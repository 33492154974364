<template>
<v-row>
    <v-col cols="12" md="8" class="pa-0">
        <template v-if="ready">
            <v-card tile class="mb-5 mr-md-4">
                <v-img :src="'https://directus.stonevibes.de/assets/'+pageData.titelbild"></v-img>
            </v-card>
            <h2 class="px-4 px-sm-0 mr-md-4 mb-5">{{pageData.subtitel}}</h2>
            <div class="px-4 px-sm-0 mr-md-4 content_area" v-html="pageData.text"></div>
        </template>
    </v-col>
    <v-col cols="12" md="4" class="pa-sm-0 pl-md-4">
        <subNav :data="subNavData" v-if="$vuetify.breakpoint.mdAndUp && subNavData" />

        <template v-if="pageData.iframe_link">
            <v-card class="my-5" tile>
                <a class="pa-4 d-inline-block" @click.stop="openVR" href="#">Virtueller Rundgang Avalonia</a>
            </v-card>
        </template>

        <v-dialog v-model="vr" max-width="1000" class="vr_wrap"  overlay-opacity="0.8">
            <iframe class="VR" :src="pageData.iframe_link"></iframe>
        </v-dialog>


        <template v-if="ready && pageData.anhang.length > 0">
            <template v-if="pageData.anhang[0].directus_files_id">
                <v-card v-for="(item, i) in pageData.anhang" :key="i" class="my-5" tile>
                    <v-img class="c-pointer" @click.stop="openDialog" v-if="item.directus_files_id.type.substring(0, 5) == 'image'" :src="'https://directus.stonevibes.de/assets/'+item.directus_files_id.id"></v-img>
                    <a target="_blank" v-else class="pa-4 d-inline-block" :href="'https://directus.stonevibes.de/assets/'+item.directus_files_id.id">{{item.directus_files_id.title}}</a>
                </v-card>
            </template>
        </template>

        <v-dialog v-model="dialog" overlay-opacity="0.8" max-width="1000" width="fit-content" v-if="ready && pageData.anhang.length > 0">
            <v-carousel height="auto" :show-arrows="pageData.anhang.length > 1" hide-delimiters class="carousel-preset">
                <template v-if="pageData.anhang[0].directus_files_id">
                    <template v-for="(item,i) in pageData.anhang">
                        <v-carousel-item v-if="item.directus_files_id.type.substring(0, 5) == 'image'" :key="i" :src="'https://directus.stonevibes.de/assets/'+item.directus_files_id.id"></v-carousel-item>
                    </template>
                </template>
            </v-carousel>
        </v-dialog>
    </v-col>
</v-row>
</template>

<script>
import {
    loadSubNav,
    loadPageData
} from '@/api/api'

//import subNav from '@/components/navigations/subNav';
export default {
    name: 'Seite',
    data: () => ({
        subNavData: [],
        pageData: {},
        ready: false,
        dialog: false,
        vr: false,
    }),
    props: ['bc'],
    methods: {
        fetchData() {
            //console.log(this.$route.params.id);
            loadPageData(this.$route.params.id).then(data => {
                this.pageData = data;
                this.ready = true;
            })


            loadSubNav(this.bc[1].id).then(data => {
                this.subNavData = data;
            })
        },
        openDialog() {
            this.dialog = true
        },
        openVR() {
            this.vr = true
        }
    },
    created() {
        this.fetchData();
    },
    components: {
        subNav: () => import('@/components/navigations/subNav')
    },
    watch: {
        $route: 'fetchData'
    }
}
</script>

<style>
.carousel-preset .v-image__image--cover {
    /*    background-size: auto;*/
}

.c-pointer {
    cursor: pointer;
}

.VR{
    min-height: 60vh;
    min-width: 100%;
    border: 0;
    margin-bottom: -6px;
}
</style>
